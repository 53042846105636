<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-col>
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        ดูข้อมูลระดับการศึกษา
      </span>
    </v-col>
    <v-card class="pa-6" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อไทย (Thai name)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="name_th"
              placeholder="ชื่อไทย (Thai name)"
              dense
              outlined
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่ออังกฤษ (English name)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="name_en"
              placeholder="ชื่ออังกฤษ (English name)"
              dense
              outlined
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <!-- <v-btn @click="submit('q_1_1')" dark color="#2AB3A3">บันทึก</v-btn> -->
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      name_th: "",
      name_en: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      loading: false,
      levelsData: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.levelsData = JSON.parse(
      Decode.decode(localStorage.getItem("levelsData"))
    );
    this.getOne();
  },
  methods: {
    async getOne() {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      console.log("this.userData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/levels/${this.levelsData.id}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getLevel", response.data.data);
      this.name_th = response.data.data.name_th;
      this.name_en = response.data.data.name_en;
      this.loading = false;
    },
    cancel() {
      this.$router.push("ManageLevelCourse");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name_th: this.name_th,
          name_en: this.name_en,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/levels/${this.levelsData.id}`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขระดับการศึกษาสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageLevelCourse");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
